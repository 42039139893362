<template>
  <div class="card-body p-2">

    <div class="form-group row">
      <label class="col-md-2 col-form-label font-weight-semibold">Accquistion From</label>
      <div class="col-md-4">
        <input  ref="txtFrom" class="form-control" type="date"  style="padding: 0px;" v-model="from_date">
      </div>
      <label class="col-md-2 col-form-label font-weight-semibold">Upto</label>
      <div class="col-md-4">
        <input class="form-control  text-center" type="date" v-model="upto_date">
      </div>
    </div>

    <div class="form-group row">
      <label class="col-md-2 col-form-label font-weight-semibold">Acc No</label>
      <div class="col-md-4">
        <input  ref="txtFromCode" class="form-control" type="number"  style="padding: 0px;" v-model="from_code">
      </div>
      <label class="col-md-2 col-form-label font-weight-semibold">Upto</label>
      <div class="col-md-4">
        <input class="form-control  text-center" type="number" v-model="upto_code">
      </div>
    </div>

    <div class="form-group row">
      <label class="col-md-2 col-form-label font-weight-semibold">Author</label>
      <div class="col-md-4">
        <select  ref="cmbAuthor" class="form-control" v-model="author_id">
          <option value="0" selected>All</option>
          <option v-for="author in authors" :value="author.id">{{author.name}}</option>
        </select>
      </div>
      <label class="col-md-2 col-form-label font-weight-semibold">Publisher</label>
      <div class="col-md-4">
        <select  ref="cmbPublisher" class="form-control" v-model="publisher_id">
          <option value="0" selected>All</option>
          <option v-for="ledger in publishers" v-bind:value="ledger.id">
            {{ ledger.name }}
          </option>
        </select>
      </div>
    </div>

    <div class="form-group row">
      <label class="col-md-2 col-form-label font-weight-semibold">Department</label>
      <div class="col-md-4">
        <select  ref="cmbDepartment" class="form-control" v-model="department_id">
          <option value="0" selected>All</option>
          <option v-for="ledger in departments" v-bind:value="ledger.id">
            {{ ledger.name }}
          </option>
        </select>
      </div>
      <label class="col-md-2 col-form-label font-weight-semibold">Category</label>
      <div class="col-md-4">
        <select  ref="cmbCategory" class="form-control" v-model="category_id">
          <option value="0" selected>All</option>
          <option v-for="ledger in categories" v-bind:value="ledger.id">
            {{ ledger.name }}
          </option>
        </select>
      </div>
    </div>

    <div class="form-group row">
      <label class="col-md-2 col-form-label font-weight-semibold">Section</label>
      <div class="col-md-4">
        <select  ref="cmbSection" class="form-control" v-model="section_id">
          <option value="0" selected>All</option>
          <option v-for="ledger in sections" v-bind:value="ledger.id">
            {{ ledger.name }}
          </option>
        </select>
      </div>
      <label class="col-md-2 col-form-label font-weight-semibold">Subject</label>
      <div class="col-md-4">
        <select  ref="cmbSubject" class="form-control" v-model="subject_id">
          <option value="0" selected>All</option>
          <option v-for="ledger in subjects" v-bind:value="ledger.id">
            {{ ledger.name }}
          </option>
        </select>
      </div>
    </div>

    <div class="form-group row" v-if="report_files.length > 0" >
      <label class="col-md-2 col-form-label font-weight-semibold">Columns</label>
      <div class="col-md-10">
        <select  ref="cmbColumns" class="form-control" v-model="report_file">
          <option value="default" selected>Default</option>
          <option v-for="fileName in report_files" v-bind:value="fileName">
            {{ fileName }}
          </option>
        </select>
      </div>
    </div>

    <div class="row align-self-end">
      <div class="col-md-12 text-right">
        <button class="btn btn-outline-success" @click="clicked">Show Report <i class="icon-printer ml-2" style="color: green;"></i> </button>
      </div>

    </div>

  </div>
</template>

<script>
import moment from 'moment';
import {userService} from '@/store/auth-header.js'
import { store } from '@/store/store.js'

export default {
  name: 'ReportFilterCard',
  components: {},

  store,
  data() {
    return {
      from_code:0,
      upto_code:0,
      from_date: '2000-01-01',//moment().add(-7,'days').format('YYYY-MM-DD'),
      upto_date: moment().format('YYYY-MM-DD'),
      authors: [],
      publishers: [],
      departments:[],
      sections:[],
      subjects:[],
      categories:[],
      report_files : [],
      author_id:0,
      publisher_id:0,
      department_id : 0,
      section_id : 0,
      subject_id : 0,
      category_id : 0,
      report_file: 'default',
    }
  },
  props:{
    myreport_files:[],
    type: Array,
    default:()=> [],
  },
  beforeMount () {
    this.$data.report_files = this.$props.myreport_files;
  },
  mounted() {
    const self = this;
    self.loadFilterData();
  },
  methods:{
    loadFilterData () {
      const self = this;

      const requestOptions = {
        method: 'GET',
        mode: "cors",
        headers: userService.authHeader()
      };

      self.$data.authors = [];
      fetch(`${process.env.VUE_APP_ROOT_API}api/lms/authors/after/1`, requestOptions).then(userService.handleResponse).then(function (resp) {
        if (resp.ok) {
          if (_.isArray(resp.data)) {
            self.$data.authors = resp.data;
          }
        } else {
          swal({ title: "Oh noes!", text: resp.msg, type: "error" });
        }
      });

      self.$data.publishers = [];
      fetch(`${process.env.VUE_APP_ROOT_API}api/lms/publishers/after/1`, requestOptions).then(userService.handleResponse).then(function (resp) {
        if (resp.ok) {
          if ( _.isArray(resp.data)) {
            self.$data.publishers = resp.data;
          }
        } else {
          swal({ title: "Oh noes!", text: resp.msg, type: "error" });
        }
      }).catch(function (err) {
      });

      self.$data.departments = [];
      fetch(`${process.env.VUE_APP_ROOT_API}api/lms/departments/after/1`, requestOptions).then(userService.handleResponse).then(function (resp) {
        if (resp.ok) {
          if ( _.isArray(resp.data)) {
            self.$data.departments = resp.data;
          }
        } else {
          swal({ title: "Oh noes!", text: resp.msg, type: "error" });
        }
      }).catch(function (err) {
      });

      self.$data.sections = [];
      fetch(`${process.env.VUE_APP_ROOT_API}api/lms/sections/after/1`, requestOptions).then(userService.handleResponse).then(function (resp) {
        if (resp.ok) {
          if ( _.isArray(resp.data)) {
            self.$data.sections = resp.data;
          }
        } else {
          swal({ title: "Oh noes!", text: resp.msg, type: "error" });
        }
      }).catch(function (err) {
      });

      self.$data.categories = [];
      fetch(`${process.env.VUE_APP_ROOT_API}api/lms/categories/after/1`, requestOptions).then(userService.handleResponse).then(function (resp) {
        if (resp.ok) {
          if ( _.isArray(resp.data)) {
            self.$data.categories = resp.data;
          }
        } else {
          swal({ title: "Oh noes!", text: resp.msg, type: "error" });
        }
      }).catch(function (err) {
      });

      self.$data.subjects = [];
      fetch(`${process.env.VUE_APP_ROOT_API}api/lms/subjects/after/1`, requestOptions).then(userService.handleResponse).then(function (resp) {
        if (resp.ok) {
          if ( _.isArray(resp.data)) {
            self.$data.subjects = resp.data;
          }
        } else {
          swal({ title: "Oh noes!", text: resp.msg, type: "error" });
        }
      }).catch(function (err) {
      });

    },
    clicked(){
      const self = this;

      let filter = `from_code=${self.$data.from_code}&upto_code=${self.$data.upto_code}&from=${self.$data.from_date}&upto=${self.$data.upto_date}&author_id=${self.$data.author_id}&publisher_id=${self.$data.publisher_id}&category_id=${self.$data.category_id}&section_id=${self.$data.section_id}&subject_id=${self.$data.subject_id}&report_file=${self.$data.report_file}`;
      filter = `from_code=${self.$data.from_code}&upto_code=${self.$data.upto_code}&from=${self.$data.from_date}&upto=${self.$data.upto_date}&author_id=${self.$data.author_id}&publisher_id=${self.$data.publisher_id}&category_id=${self.$data.category_id}&section_id=${self.$data.section_id}&subject_id=${self.$data.subject_id}`;

      self.$emit('handle_report_filter_options', filter, self.$data.report_file);

    }
  }
}
</script>

<style scoped>
input:focus {
  background: #feff00;

}

textarea:focus {
  background: #feff00;

}

select:focus {
  background: #feff00;
}

button:focus {
  background: #feff00;
  color: #0a0a0a!important;
}
</style>
